import React, { useState, useEffect } from 'react';
import { Element } from 'react-scroll';
import Section from '../../common/section';
import * as style from './experience-section.module.scss';
import ExperienceList from '../experience-list/experience-list';
import SkillList from '../skill-list/skill-list';
import { IExperience } from '../../common/experience';
import ExperienceContext, { ExperienceBox } from './experience-context';

// TODO refactor data
/* eslint-disable max-len */
const experiences = [
  {
    description:
      'Worked closely with the clients to create projects from scratch based on the best suited tools and frameworks',
    skills: [
      'React',
      'Typescript',
      'CSS in JS',
      'State management concepts',
      'REST services',
      'Gatsby',
      'Kanban / Scrum'
    ]
  },
  {
    description:
      'I Planned and implemented micro-frontend architecture on different projects and I helped to create reusable component libraries for companies',
    skills: ['Micro-Frontend', 'Atomic design', 'Storybook', 'Material UI']
  },
  {
    description:
      'I configured strict release process including build, lint, test coverage check and code review steps to produce well tested quality code and stable applications',
    skills: ['Azure DevOps', 'CI / CD setup', 'Jest / Unit testing', 'Webpack']
  },
  {
    description:
      'Managing and leading the team of software developers worked on the different part of the project. I oversaw the full lifecycle of the project and my soft skills have improved',
    skills: ['Project management', 'Team guidance', 'Remote work']
  },
  {
    description:
      'Interviewing new Frontend developer candidates and participating in the onboarding flow by offering “get to know” lunch. I have made lot of friends during these meals ;-)',
    skills: ['People management', 'Mentoring']
  }
];
/* eslint-enable max-len */

const skillSet = experiences.reduce((list: Set<string>, experience: IExperience) => {
  experience.skills.forEach(skill => list.add(skill));
  return list;
}, new Set<string>());

const skillList = Array.from(skillSet);

const halfExperience = Math.ceil(experiences.length / 2);
const descriptionsLeft = experiences.slice(0, halfExperience);
const descriptionsRight = experiences.slice(halfExperience, experiences.length);

const ExperienceSection: React.FC = () => {
  const [experience, setExperience] = useState<ExperienceBox | null>(null);
  const [skills, setSkills] = useState<string[]>([]);

  useEffect(() => {
    const activeSkills = experience ? experience.skills : [];
    setSkills(activeSkills);
  }, [experience]);

  return (
    <Element name={Section.EXPERIENCE} className={style.experienceSection}>
      <ExperienceContext.Provider value={{ setExperience, experience, skills }}>
        <h2 className={style.title}>
          Experiences <span className={style.titlePart}>and my previous</span> responsibilities
        </h2>
        <section className={style.wrapper}>
          <div className={style.leftColumn}>
            <ExperienceList experiences={descriptionsLeft} />
          </div>
          <div className={style.middleColumn}>
            <SkillList skills={skillList} />
          </div>
          <div className={style.rightColumn}>
            <ExperienceList experiences={descriptionsRight} />
          </div>
        </section>
      </ExperienceContext.Provider>
    </Element>
  );
};

export default ExperienceSection;
