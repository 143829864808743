import { IExperience } from 'common/experience';
import React from 'react';
import Experience from '../experience/experience';

type ExperienceListProps = {
  experiences: IExperience[];
};

const ExperienceList: React.FC<ExperienceListProps> = ({ experiences }) => (
  <>
    {experiences.map((experience, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <Experience key={`experience_box_${i}`} experience={experience} index={i} />
    ))}
  </>
);

export default ExperienceList;
