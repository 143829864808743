import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import HeroSection from '../components/hero-section/hero-section';
import ExperienceSection from '../components/experience-section/experience-section';
import ContactSection from '../components/contact-section/contact-section';
import CookieConsent from '../components/cookie-consent/cookie-consent';

const IndexPage: React.FC = () => (
  <Layout>
    <SEO title="Developer" />
    <HeroSection />
    <ExperienceSection />
    <ContactSection />
    <CookieConsent />
  </Layout>
);

export default IndexPage;
