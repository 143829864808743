import React, { useContext, useLayoutEffect, useState } from 'react';
import * as style from './skill-list.module.scss';
import classNames from 'classnames/bind';
import ExperienceContext from '../experience-section/experience-context';

type skillListProps = {
  skills: string[];
};

const cx = classNames.bind(style);

const getTopPosition = (offset: number) => {
  const CONTAINER_MARGIN = 80;
  return `${offset - CONTAINER_MARGIN}px`;
};

const SkillList: React.FC<skillListProps> = ({ skills }) => {
  const { skills: relatedSkills, experience } = useContext(ExperienceContext);
  const [relatedSkillsTop, setRelatedSkillsTop] = useState<string>('0px');

  useLayoutEffect(() => {
    if (!experience) return;

    setRelatedSkillsTop(getTopPosition(experience.offsetTop));
  }, [experience]);

  return (
    <>
      <div style={{ top: relatedSkillsTop }} className={style.relatedSkillsContainer}>
        {relatedSkills.length ? <h4 className={style.relatedTitle}>Related skills</h4> : null}
        {relatedSkills.map(skill => (
          <p key={skill}>{skill}</p>
        ))}
      </div>
      <div className={cx('notRelatedSkillsContainer', { inactive: experience })}>
        {skills.map(skill => (
          <p key={skill}>{skill}</p>
        ))}
      </div>
    </>
  );
};

export default SkillList;
