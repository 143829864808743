import React, { useState, useRef } from 'react';
import { Element } from 'react-scroll';
import Section from '../../common/section';
import * as style from './contact-section.module.scss';
import classNames from 'classnames/bind';
import Button from '../button/button';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm, ErrorMessage } from 'react-hook-form';
import { toast } from 'react-toastify';
import useThemeContext from '../../hooks/use-theme-context';
import ThemeType from '../../common/theme-type';

const cx = classNames.bind(style);

type RecaptchaState = {
  'g-recaptcha-response': string | null;
};

type ContactFormData = {
  name: string;
  email: string;
  message: string;
};

const FormFieldValidations = {
  name: {
    required: {
      value: true,
      message: 'Name is required'
    },
    minLength: {
      value: 3,
      message: 'Name is too short'
    },
    maxLength: {
      value: 50,
      message: 'Name is too long'
    }
  },
  email: {
    required: {
      value: true,
      message: 'Email is required'
    },
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: 'Invalid email address'
    }
  },
  message: {
    required: {
      value: true,
      message: 'Message is required'
    }
  }
};

function encode(data: { [key: string]: string | null }) {
  return Object.keys(data)
    .map((key: string) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key] ?? '')}`)
    .join('&');
}

const ContactSection: React.FC = () => {
  const { theme } = useThemeContext();
  const { register, handleSubmit, errors, reset } = useForm<ContactFormData>({ mode: 'onBlur' });
  const [recaptchaState, setRecaptchaState] = useState<RecaptchaState>({
    'g-recaptcha-response': null
  });
  const formEl = useRef<HTMLFormElement>(null);

  const onSubmit = async (data: ContactFormData) => {
    if (!formEl.current) return;

    try {
      await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': formEl.current.getAttribute('name'),
          ...recaptchaState,
          ...data
        })
      });
      toast('Thank you for reaching out to me!');
      reset();
    } catch (error) {
      toast.warn('Oops, something went wrong. Please try again later...');
    }
  };

  const handleRecaptcha = (value: string | null) => {
    setRecaptchaState({ 'g-recaptcha-response': value });
  };

  return (
    <Element name={Section.CONTACT}>
      <section className={style.contactSection}>
        <h2 className={style.title}>Contact</h2>
        <h3 className={style.subSentence}>
          <b>Interested in working together?</b> Fill out the contact form and get in touch with me.
        </h3>
        <form
          ref={formEl}
          onSubmit={handleSubmit(onSubmit)}
          className={style.form}
          name="contact-form"
          method="POST"
          data-netlify-recaptcha="true"
          data-netlify="true"
        >
          <input type="hidden" name="form-name" value="contact-form" />
          <div className={style.inputWrapper}>
            <input
              className={cx('input', 'nameInput')}
              name="name"
              type="text"
              placeholder="Name"
              ref={register(FormFieldValidations.name)}
              aria-label="Name"
              aria-invalid={errors.name ? 'true' : 'false'}
              aria-describedby="error-name"
            />
            <span role="alert" id="error-name" className={style.errorMessage}>
              <ErrorMessage errors={errors} name="name" />
            </span>
          </div>

          <div className={style.inputWrapper}>
            <input
              className={cx('input', 'emailInput')}
              name="email"
              type="email"
              placeholder="Email address"
              ref={register(FormFieldValidations.email)}
              aria-label="Email"
              aria-invalid={errors.email ? 'true' : 'false'}
              aria-describedby="error-email"
            />
            <span role="alert" id="error-email" className={style.errorMessage}>
              <ErrorMessage errors={errors} name="email" />
            </span>
          </div>

          <div className={cx('areaInput', 'inputWrapper')}>
            <textarea
              className={style.input}
              name="message"
              placeholder="Message"
              ref={register(FormFieldValidations.message)}
              aria-label="Message"
              aria-invalid={errors.message ? 'true' : 'false'}
              aria-describedby="error-message"
            />
            <span role="alert" id="error-message" className={style.errorMessage}>
              <ErrorMessage errors={errors} name="message" />
            </span>
          </div>

          <div className={style.sendButton}>
            <ReCAPTCHA
              sitekey={`${process.env.SITE_RECAPTCHA_KEY}`}
              onChange={handleRecaptcha}
              theme={theme === ThemeType.LIGHT ? 'light' : 'dark'}
            />
            <Button type="submit" caption="Send Message" />
          </div>
        </form>
      </section>
    </Element>
  );
};

export default ContactSection;
