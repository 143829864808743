import { useState, useEffect, useCallback } from 'react';

const useScrollReveal = <T extends HTMLElement>(element: T | null): [boolean] => {
  const SCROLL_OFFSET = 150;
  const [isVisible, setVisible] = useState(false);

  const handleVisibility = useCallback(() => {
    if (element && window.pageYOffset > element.offsetTop + SCROLL_OFFSET) {
      setVisible(true);
    }
  }, [element]);

  useEffect(() => {
    handleVisibility();
  }, [handleVisibility]);

  useEffect(() => {
    if (!isVisible) {
      document.addEventListener('scroll', handleVisibility);
    }
    return () => {
      document.removeEventListener('scroll', handleVisibility);
    };
  }, [isVisible, handleVisibility]);

  return [isVisible];
};

export default useScrollReveal;
