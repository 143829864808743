import React from 'react';
import * as style from './hero.module.scss';
import HeroImage from '../hero-image/hero-image';

const Hero: React.FC = () => {
  const heroSize = 10;
  return (
    <>
      <HeroImage size={heroSize} />
      <article className={style.description}>
        <h1 className={style.title}>
          Hello! <span className={style.heroName}>I'm Norbert Bartos.</span>
        </h1>
        <h2 className={style.tagline}>I help you to plan and develop web apps.</h2>
        <h2 className={style.subTitle}>
          As an experienced <span className={style.highlight}>Frontend Web Developer</span> I can collaborate with you
          to plan the Frontend architecture of your web app and develop the user interface using modern tools.
        </h2>
      </article>
    </>
  );
};

export default Hero;
