import React from 'react';
import Hero from '../hero/hero';
import * as style from './hero-section.module.scss';
import ThemeSwitch from '../theme-switch/theme-switch';
import Button from '../button/button';
import useScrollToSection from '../../hooks/use-scroll-to-section';
import Section from '../../common/section';

const HeroSection: React.FC = () => {
  const scrollToExperience = useScrollToSection(Section.EXPERIENCE);
  const scrollToContact = useScrollToSection(Section.CONTACT);

  return (
    <section className={style.heroSection}>
      <ThemeSwitch />
      <Hero />
      <div className={style.actionButtonsContainer}>
        <Button caption="Experiences" width="140px" onClick={scrollToExperience} />
        <Button caption="Get in touch" width="140px" onClick={scrollToContact} />
      </div>
    </section>
  );
};

export default HeroSection;
