import { useState, useEffect, useCallback } from 'react';

interface IUseHover {
  isHovered: boolean;
}

const useHover = <T extends HTMLElement>(element: T | null): IUseHover => {
  const [isHovered, setHovered] = useState(false);

  const handleMouseOver = useCallback(() => setHovered(true), []);
  const handleMouseOut = useCallback(() => setHovered(false), []);

  useEffect(() => {
    const node = element;
    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('focus', handleMouseOver);

      node.addEventListener('mouseout', handleMouseOut);
      node.addEventListener('blur', handleMouseOut);

      return () => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('focus', handleMouseOver);

        node.removeEventListener('mouseout', handleMouseOut);
        node.removeEventListener('blur', handleMouseOut);
      };
    }
  }, [element, handleMouseOver, handleMouseOut]);

  return { isHovered };
};

export default useHover;
