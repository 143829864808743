// extracted by mini-css-extract-plugin
export var areaInput = "contact-section-module--areaInput--qPltA";
export var buttonElement = "contact-section-module--buttonElement--MImCH";
export var contactSection = "contact-section-module--contactSection---peqb";
export var emailInput = "contact-section-module--emailInput---pV34";
export var errorMessage = "contact-section-module--errorMessage--r4E8G";
export var form = "contact-section-module--form--HF-bw";
export var input = "contact-section-module--input--J6UkV contact-section-module--inputElement--rT+8G";
export var inputElement = "contact-section-module--inputElement--rT+8G";
export var inputWrapper = "contact-section-module--inputWrapper--zKA2I";
export var nameInput = "contact-section-module--nameInput--hkOwb";
export var sendButton = "contact-section-module--sendButton---GGOQ";
export var subSentence = "contact-section-module--subSentence--NkYb4";
export var title = "contact-section-module--title--4eXOB";