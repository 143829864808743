import React from 'react';
import { IExperience } from '../../common/experience';

export type ExperienceBox = IExperience & {
  index: number;
  offsetTop: number;
};

interface IExperienceContext {
  experience: ExperienceBox | null;
  skills: string[];
  setExperience?: (experience: ExperienceBox | null) => void;
}

const ExperienceContext = React.createContext<IExperienceContext>({
  experience: null,
  skills: []
});

export default ExperienceContext;
