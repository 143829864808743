import Section from '../common/section';
import { scroller } from 'react-scroll';
import { useCallback } from 'react';

const defaultOptions = {
  smooth: true,
  duration: 500,
  offset: -20
};

type ScrollToSectionFn = () => void;

const useScrollToSection = (sectionType: Section, options = defaultOptions): ScrollToSectionFn => {
  const scrollTo = useCallback(
    (section: Section) => {
      scroller.scrollTo(section, options);
    },
    [options]
  );

  return useCallback(() => scrollTo(sectionType), [sectionType, scrollTo]);
};

export default useScrollToSection;
