import React, { useEffect, useState } from 'react';
import * as style from './cookie-consent.module.scss';
import { Link } from 'gatsby';

const CookieAcceptStorageKey = 'norbertbsiteCookieAccept';

const CookieConsent: React.FC = () => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    const isCookieAccept = localStorage.getItem(CookieAcceptStorageKey) === 'accepted';
    setActive(!isCookieAccept);
  }, []);

  const close = () => {
    setActive(false);
    localStorage.setItem(CookieAcceptStorageKey, 'accepted');
  };

  if (!active) return null;

  return (
    <div className={style.consentContainer}>
      <div className={style.header}>
        <h3>Cookies</h3>
        <span className={style.closeBtn} role="button" onClick={close} tabIndex={-1}>
          X
        </span>
      </div>
      <p>
        This website or its third-party tools use cookies or other identifiers. Learn more:{' '}
        <Link to="/privacy-and-policy">Privacy policy</Link>
        .
        <br />
        You accept the use of cookies or other identifiers by closing this notice.
      </p>
    </div>
  );
};

export default CookieConsent;
