import React, { useContext, useEffect, useRef } from 'react';
import * as style from './experience.module.scss';
import useHover from '../../hooks/use-hover';
import classNames from 'classnames/bind';
import useScrollReveal from '../../hooks/use-scroll-reveal';
import { IExperience } from '../../common/experience';
import ExperienceContext from '../experience-section/experience-context';

const cx = classNames.bind(style);

type ExperienceProps = {
  index: number;
  experience: IExperience;
};

const Experience: React.FC<ExperienceProps> = ({ experience, index }) => {
  const { setExperience } = useContext(ExperienceContext);
  const hoverRef = useRef<HTMLDivElement>(null);
  const { isHovered } = useHover<HTMLDivElement>(hoverRef.current);
  const [isVisible] = useScrollReveal<HTMLDivElement>(hoverRef.current);

  useEffect(() => {
    if (!hoverRef.current || !setExperience) return;

    const { offsetTop } = hoverRef.current;
    const activeExperience = isHovered ? { ...experience, offsetTop, index } : null;

    setExperience(activeExperience);
  }, [isHovered, hoverRef, setExperience, experience, index]);

  return (
    <>
      <div role="button" className={cx('experienceBox', { visibleBox: isVisible })} ref={hoverRef} tabIndex={0}>
        {experience.description}
      </div>
      <div className={style.relatedSkills}>
        <h4>Related skills:</h4>
        {experience.skills.map(skill => (
          <span key={skill}>.{skill}.</span>
        ))}
      </div>
    </>
  );
};

export default Experience;
